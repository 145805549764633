import { Injectable } from '@angular/core';
import { Observable, catchError, delay, of, tap, throwError } from 'rxjs';
import { AUTH_VARIABLES } from '../utils/auth-variables';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils/local-storage-utils';
import { UserInfo } from '../../core/store/company/company.model';
import { UserRole } from '../../core/utils/roles';
import { AuthApiService, LoginResp } from '../../core/api/auth-api.service';

type SignInProps = {
  username: string;
  password: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(public authAPI: AuthApiService) {}

  signIn({ username, password }: SignInProps): Observable<LoginResp> {
      return this.authAPI.login({email: username, password: password}).pipe(tap(res => {
        setLocalStorage(AUTH_VARIABLES.userInfo, res.user.email);
        setLocalStorage(AUTH_VARIABLES.authToken, res.token);
        setLocalStorage(AUTH_VARIABLES.userRole, res.user.role);
      }), catchError(() => {
        return throwError(() => new Error('Username or password is incorrect'))
      }));
  }

  signOut(): Observable<boolean> {
    removeLocalStorage(AUTH_VARIABLES.userInfo);
    removeLocalStorage(AUTH_VARIABLES.authToken);
    return of(true).pipe(delay(500));
  }

  isUserLoggedIn(): boolean {
    const token = getLocalStorage(AUTH_VARIABLES.authToken);
    return !!token;
  }

  getAuthToken(): string {
    return getLocalStorage(AUTH_VARIABLES.authToken);
  }

  getUserRole(): UserRole {
    const userRole = getLocalStorage(AUTH_VARIABLES.userRole) as UserRole;
    return userRole;
  }

  didUserSayWhoHeIs(): boolean {
    const userInfo = JSON.parse(getLocalStorage(AUTH_VARIABLES.companyUserInfo)) as UserInfo;
    if (!userInfo) return false;

    const { createByEmail = '', createByName = '' } = userInfo;
    return createByEmail?.length > 0 && createByName?.length > 0;
  }
}
